import React from 'react';

interface LanguageToggleButtonProps {
  language: string;
  toggleLanguage: () => void;
}

const LanguageToggleButton: React.FC<LanguageToggleButtonProps> = ({ language, toggleLanguage }) => {
  return (
    <button
      onClick={toggleLanguage}
      className="px-2 py-1 bg-gray-800 text-white text-sm rounded-md hover:bg-gray-700 transition duration-200"
    >
      {language === 'en' ? 'FR' : 'EN'}
    </button>
  );
};

export default LanguageToggleButton;