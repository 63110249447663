import React from 'react';
import emailjs from 'emailjs-com';

const ContactForm: React.FC = () => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    emailjs.sendForm('service_d634vwq', 'template_9hdmcaq', e.currentTarget, 'RV5BN9d-2Q94aQLvR')
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message!');
      });
  };

  return (
    <div className="bg-gray-100 p-8 m-8 rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Contact Me</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          name="from_name"
          placeholder="Your Name"
          required
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
        />
        <input
          type="email"
          name="from_email"
          placeholder="Your Email"
          required
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
        />
        <textarea
          name="message"
          placeholder="Your needs and questions"
          required
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
        />
        <button
          type="submit"
          className="w-full bg-gray-800 text-white p-2 rounded-md hover:bg-gray-700 transition duration-200"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default ContactForm;