import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../common/ContactForm';
import background from '../img/background.jpg';
import sof from '../img/sof.png';
import { Joystick, TrendingUp, Bot } from 'lucide-react';
import LanguageToggleButton from '../common/LanguageToggleButton';
import SEO from '../common/SEO';

const HomePage: React.FC = () => {
  const [language, setLanguage] = useState<'en' | 'fr'>('en');

  const toggleLanguage = () => {
    setLanguage((prevLanguage: string) => (prevLanguage === 'en' ? 'fr' : 'en'));
  };

  const content = {
    en: {
      welcome: 'Welcome',
      description: 'Based in Belgium, for companies in Europe',
      partner: 'Sales - Your Sales Excellence Partner',
    },
    fr: {
      welcome: 'Bienvenue',
      description: 'Depuis la Belgique, pour les entreprises en Europe',
      partner: 'Ventes - Votre partenaire d\'excellence en ventes',
    },
  };

  const currentContent = content[language];

  const servicesContent = {
    en: [
      {
        title: 'Sales Direction Outsourcing',
        description: 'Externalize sales direction to scale up and standardize your sales department.',
        icon: Joystick,
        path: '/services/sales-direction-outsourcing'
      },
      {
        title: 'Comprehensive Sales Strategy',
        description: 'Create a strategy aligned with your business objectives and target market.',
        icon: TrendingUp,
        path: '/services/sales-strategy'
      },
      {
        title: 'AI-Powered Sales Optimization',
        description: 'Leverage cutting-edge AI technologies to automate and enhance your sales processes.',
        icon: Bot,
        path: '/services/ai-optimization'
      },
    ],
    fr: [
      {
        title: 'Externalisation de la direction des ventes',
        description: 'Externalisez la direction des ventes pour développer et standardiser votre département commercial.',
        icon: Joystick,
        path: '/services/sales-direction-outsourcing'
      },
      {
        title: 'Stratégie de vente 360',
        description: 'Créez une stratégie alignée avec vos objectifs commerciaux et votre marché cible.',
        icon: TrendingUp,
        path: '/services/sales-strategy'
      },
      {
        title: 'Optimisation des ventes par IA',
        description: 'Exploitez les technologies d\'IA de pointe pour automatiser et améliorer vos processus de vente.',
        icon: Bot,
        path: '/services/ai-optimization'
      },
    ],
  };

  return (
    <div>
      <SEO pageTitle="Besales - Scale and Optimize Your Sales" pageDescription="Besales by Sophie Monteragioni helps you scale and optimize your sales by outsourcing your Sales Direction. Improve your search visibility and drive more customers." />
      <div className="relative min-h-screen flex flex-col items-center bg-gray-100 pt-5 px-5">
        <div className="fixed inset-0 bg-cover bg-center grayscale" style={{ backgroundImage: `url(${background})` }}></div>
        <div className="inline-block transform transition-transform w-full sm:w-9/12 h-[40rem] duration-200 hover:scale-105 mt-10">
          <div className="bg-white bg-opacity-75 p-6 sm:p-12 rounded-lg shadow-lg w-full h-full">
            <div className="flex flex-col sm:flex-row items-center justify-between">
              <div className="text-center sm:text-left">
                <h1 className="text-4xl sm:text-8xl font-bold font-lato">Besales</h1>
                <p className="text-xl sm:text-3xl mt-4 font-lato">by Sophie Monteragioni</p>
              </div>
              <img src={sof} alt="Sophie Monteragioni" className="h-24 w-24 sm:h-48 sm:w-48 rounded-full mt-4 sm:mt-0" />
            </div>
            <p className="text-lg sm:text-3xl font-medium mt-8 font-lato text-center sm:text-left">
              {currentContent.description}
            </p>
          </div>
        </div>
        <div className="relative z-10 w-full flex-grow overflow-y-auto">
          <div className="bg-white bg-opacity-70 rounded-lg shadow-lg mx-auto my-8 max-w-4xl w-11/12 backdrop-blur-md">
            <div className="sticky top-0 bg-gray-200 bg-opacity-80 p-2 flex items-center rounded-t-lg">
              <div className="flex gap-2">
                <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                <div className="w-3 h-3 bg-green-500 rounded-full"></div>
              </div>
              <div className="flex-grow text-center text-gray-700 text-sm">{currentContent.partner}</div>
              <div className="absolute top-2 right-2">
                <LanguageToggleButton language={language} toggleLanguage={toggleLanguage} />
              </div>
            </div>
            <div className="p-8 bg-white bg-opacity-60 rounded-b-lg">
              <h2 className="text-2xl font-semibold mb-4">{currentContent.welcome}</h2>
              <p className="mb-8">{currentContent.description}</p>

              <div className="container mx-auto py-12">
                <h1 className="text-4xl font-extrabold mb-8 text-gray-800">{language === 'en' ? 'Services' : 'Services'}</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {servicesContent[language].map((service, index) => (
                    <Link to={service.path} key={index} className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition">
                      <div className="flex items-center space-x-4">
                        <service.icon className="w-12 h-12 text-blue-500" />
                        <div>
                          <h3 className="text-xl font-semibold text-gray-800">{service.title}</h3>
                          <p className="text-gray-600">{service.description}</p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;