import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, BarChart4, Boxes, Users, BatteryCharging, LineChart, Presentation, CheckSquare, Target, Webhook, Award } from 'lucide-react';
import ContactForm from '../common/ContactForm';
import LanguageToggleButton from '../common/LanguageToggleButton';
import SEO from '../common/SEO';

const BackButton = () => {
  return (
    <Link to="/" className="flex items-center space-x-2 text-gray-600 hover:text-gray-800">
      <ChevronLeft className="w-5 h-5" />
      <span className="text-sm font-medium">Back</span>
    </Link>
  );
};

const SalesDirectionOutsourcingPage: React.FC = () => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'fr' : 'en'));
  };

  const observerRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-visible');
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: '0px'
      }
    );

    observerRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => observer.disconnect();
  }, []);

  const introText = {
    en: (
      <>
        <p>
          Managing daily operations is challenging: Between tasks and emergencies, structuring a long-term strategy becomes nearly impossible. Yet, a <span className="text-2xl font-bold">clear and structured commercial direction</span> is essential for your company's growth.
        </p>
        <p>
          Transform Your Sales Leadership: What if you could benefit from expert commercial direction without having to hire a full-time director?
        </p>
        <p>
          Our Solution: <span className="text-2xl font-bold">Part-Time Commercial Direction</span>: Gain access to seasoned leadership expertise without the permanent contract constraints.
        </p>
        <p>
          Your Benefits: Focus on <span className="text-2xl font-bold">scale and optimization</span> of your sales team to drive real business impact, while maintaining flexibility and cost-effectiveness.
        </p>
      </>
    ),
    fr: (
      <>
        <p>
          Gérer les opérations quotidiennes est un défi : entre les tâches et les urgences, structurer une stratégie à long terme devient presque impossible. Pourtant, une <span className="text-2xl font-bold">direction commerciale claire et structurée</span> est essentielle pour la croissance de votre entreprise.
        </p>
        <p>
          Transformez votre leadership commercial : Et si vous pouviez bénéficier d'une direction commerciale experte sans avoir à embaucher un directeur à temps plein ?
        </p>
        <p>
          Notre solution : <span className="text-2xl font-bold">Direction commerciale à temps partiel</span> : accédez à une expertise en leadership chevronnée sans les contraintes d'un contrat permanent.
        </p>
        <p>
          Vos avantages : Concentrez-vous sur <span className="text-2xl font-bold">l'échelle et l'optimisation</span> de votre équipe de vente pour avoir un réel impact sur votre entreprise, tout en maintenant flexibilité et rentabilité.
        </p>
      </>
    )
  };

  const painPoints = [
    {
      icon: BarChart4,
      title: language === 'en' ? "Difficulty in Structuring Sales" : "Difficulté à structurer les ventes",
      description: language === 'en' ? "Unclear sales strategy and lack of defined processes. A lack of structure in sales can lead to inefficiencies, poorly targeted prospecting, and suboptimal commercial results." : "Stratégie de vente peu claire et manque de processus définis. Un manque de structure dans les ventes peut entraîner des inefficacités, un ciblage mal orienté et des résultats commerciaux sous-optimaux."
    },
    {
      icon: Boxes,
      title: language === 'en' ? "Limited Resources for Effective Sales Strategy" : "Ressources limitées pour une stratégie de vente efficace",
      description: language === 'en' ? "Limited resources to execute ambitious sales plans. SMEs often have limited human or financial resources to implement ambitious sales strategies and maintain sustained performance." : "Ressources limitées pour exécuter des plans de vente ambitieux. Les PME ont souvent des ressources humaines ou financières limitées pour mettre en œuvre des stratégies de vente ambitieuses et maintenir des performances soutenues."
    },
    {
      icon: Users,
      title: language === 'en' ? "Sales Team Management Issues" : "Problèmes de gestion de l'équipe de vente",
      description: language === 'en' ? "Difficulty in managing and motivating the sales team. The sales team may lack direction, training, or motivation, affecting overall sales performance." : "Difficulté à gérer et à motiver l'équipe de vente. L'équipe de vente peut manquer de direction, de formation ou de motivation, ce qui affecte les performances globales des ventes."
    },
    {
      icon: BatteryCharging,
      title: language === 'en' ? "Burnout and Work Overload" : "Burnout et surcharge de travail",
      description: language === 'en' ? "Managing multiple roles and constant stress. Leaders are often exhausted by multitasking and managing several areas simultaneously, preventing them from focusing on essentials." : "Gérer plusieurs rôles et un stress constant. Les dirigeants sont souvent épuisés par le multitâche et la gestion de plusieurs domaines simultanément, les empêchant de se concentrer sur l'essentiel."
    },
    {
      icon: LineChart,
      title: language === 'en' ? "Lack of Performance Visibility" : "Manque de visibilité sur les performances",
      description: language === 'en' ? "Lack of monitoring and data on sales performance. Without clear KPIs and rigorous results tracking, it's difficult to identify what works, what doesn't, and adjust strategy accordingly." : "Manque de suivi et de données sur les performances commerciales. Sans KPI clairs et un suivi rigoureux des résultats, il est difficile d'identifier ce qui fonctionne, ce qui ne fonctionne pas et d'ajuster la stratégie en conséquence."
    }
  ];

  const solutions = [
    {
      icon: Presentation,
      title: language === 'en' ? "On-demand Strategic Support" : "Support stratégique à la demande",
      subtitle: language === 'en' ? "Free up time for long-term strategy" : "Libérez du temps pour la stratégie à long terme",
      description: language === 'en' ? "We help you define and implement a clear sales strategy while freeing you from daily constraints to focus on what's essential. Our strategic support adapts to your schedule and needs, providing expert guidance exactly when you need it most." : "Nous vous aidons à définir et à mettre en œuvre une stratégie commerciale claire tout en vous libérant des contraintes quotidiennes pour vous concentrer sur l'essentiel. Notre support stratégique s'adapte à votre emploi du temps et à vos besoins, offrant des conseils d'experts exactement quand vous en avez le plus besoin.",
      benefits: language === 'en' ? [
        "Flexible strategic planning sessions",
        "Regular strategy reviews and adjustments",
        "Priority-based support system",
        "Clear action plans and milestones"
      ] : [
        "Sessions de planification stratégique flexibles",
        "Révisions et ajustements réguliers de la stratégie",
        "Système de support basé sur les priorités",
        "Plans d'action clairs et jalons"
      ]
    },
    {
      icon: CheckSquare,
      title: language === 'en' ? "Sales Process Structuring and Optimization" : "Structuration et optimisation du processus de vente",
      subtitle: language === 'en' ? "Optimize your sales process" : "Optimisez votre processus de vente",
      description: language === 'en' ? "We assist you in implementing efficient sales processes, adapted to your needs and market, to maximize the effectiveness of your sales actions. Our structured approach ensures every step of your sales process is optimized for maximum impact." : "Nous vous aidons à mettre en œuvre des processus de vente efficaces, adaptés à vos besoins et à votre marché, pour maximiser l'efficacité de vos actions commerciales. Notre approche structurée garantit que chaque étape de votre processus de vente est optimisée pour un impact maximal.",
      benefits: language === 'en' ? [
        "Complete sales process audit",
        "Custom optimization strategies",
        "Implementation support",
        "Performance tracking systems"
      ] : [
        "Audit complet du processus de vente",
        "Stratégies d'optimisation personnalisées",
        "Support à la mise en œuvre",
        "Systèmes de suivi des performances"
      ]
    },
    {
      icon: Target,
      title: language === 'en' ? "Part-time Sales Direction" : "Direction commerciale à temps partiel",
      subtitle: language === 'en' ? "Develop a sales strategy with optimized resources" : "Développez une stratégie commerciale avec des ressources optimisées",
      description: language === 'en' ? "We provide the necessary expertise to develop and execute sales action plans while optimizing your internal resources, with flexible management adapted to your means. Get the benefits of experienced sales leadership without the full-time commitment." : "Nous fournissons l'expertise nécessaire pour développer et exécuter des plans d'action commerciaux tout en optimisant vos ressources internes, avec une gestion flexible adaptée à vos moyens. Bénéficiez des avantages d'un leadership commercial expérimenté sans l'engagement à temps plein.",
      benefits: language === 'en' ? [
        "Experienced sales leadership",
        "Resource optimization",
        "Flexible commitment options",
        "Strategic planning and execution"
      ] : [
        "Leadership commercial expérimenté",
        "Optimisation des ressources",
        "Options d'engagement flexibles",
        "Planification et exécution stratégiques"
      ]
    },
    {
      icon: Webhook,
      title: language === 'en' ? "Flexible and Custom Strategic Support" : "Support stratégique flexible et personnalisé",
      subtitle: language === 'en' ? "Free yourself from overwork" : "Libérez-vous de la surcharge de travail",
      description: language === 'en' ? "We adapt to your priorities and help you delegate sales management to lighten your workload, allowing you to focus on overall business management. Our flexible support system ensures you get the help you need, when you need it." : "Nous nous adaptons à vos priorités et vous aidons à déléguer la gestion des ventes pour alléger votre charge de travail, vous permettant de vous concentrer sur la gestion globale de l'entreprise. Notre système de support flexible garantit que vous obtenez l'aide dont vous avez besoin, quand vous en avez besoin.",
      benefits: language === 'en' ? [
        "Customized support plans",
        "Workload optimization",
        "Priority management",
        "Delegation strategies"
      ] : [
        "Plans de support personnalisés",
        "Optimisation de la charge de travail",
        "Gestion des priorités",
        "Stratégies de délégation"
      ]
    },
    {
      icon: Award,
      title: language === 'en' ? "KPI Implementation and Performance Monitoring" : "Mise en œuvre des KPI et suivi des performances",
      subtitle: language === 'en' ? "Track and adjust your sales results" : "Suivez et ajustez vos résultats de vente",
      description: language === 'en' ? "We help you define clear key performance indicators (KPIs), track your sales results, and quickly adjust your actions to improve performance. Our data-driven approach ensures your sales strategy is always aligned with your business goals." : "Nous vous aidons à définir des indicateurs clés de performance (KPI) clairs, à suivre vos résultats de vente et à ajuster rapidement vos actions pour améliorer les performances. Notre approche basée sur les données garantit que votre stratégie commerciale est toujours alignée sur vos objectifs commerciaux.",
      benefits: language === 'en' ? [
        "Custom KPI development",
        "Real-time performance tracking",
        "Regular performance reviews",
        "Data-driven strategy adjustments"
      ] : [
        "Développement de KPI personnalisés",
        "Suivi des performances en temps réel",
        "Révisions régulières des performances",
        "Ajustements stratégiques basés sur les données"
      ]
    }
  ];

  return (
    <div className="relative z-10 max-w-7xl mx-auto font-sans p-6">
      <SEO pageTitle="Besales - Scale and Optimize Your Sales" pageDescription="Besales by Sophie Monteragioni helps you scale and optimize your sales by outsourcing your Sales Direction. Improve your search visibility and drive more customers." />
      <div className="flex justify-between items-center mb-8">
        <BackButton />
        <LanguageToggleButton language={language} toggleLanguage={toggleLanguage} />
      </div>

      <h1 className="text-4xl font-extrabold mb-12 text-gray-800 tracking-tight leading-tight">
        {language === 'en' ? 'Sales Direction Outsourcing' : 'Externalisation de la Direction Commerciale'}
      </h1>

      <div className="space-y-6 text-base text-gray-700">
        {language === 'en' ? introText.en : introText.fr}
      </div>

      <h2 className="text-3xl font-extrabold mt-12 mb-6 text-gray-800 tracking-tight leading-tight">
        {language === 'en' ? 'Common Challenges' : 'Défis Communs'}
      </h2>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {painPoints.map((point, index) => (
          <div key={index} className="bg-white rounded-lg p-6 shadow-md border border-gray-200">
            <point.icon size={32} className="mb-3 text-[#4a4a4a]" />
            <h3 className="text-xl font-semibold mb-2">{point.title}</h3>
            <p className="text-gray-700">{point.description}</p>
          </div>
        ))}
      </div>

      <h2 className="text-3xl font-extrabold mt-12 mb-6 text-gray-800 tracking-tight leading-tight">
        {language === 'en' ? 'Our Solutions' : 'Nos Solutions'}
      </h2>
      <div className="space-y-8">
        {solutions.map((solution, index) => (
          <div
            key={index}
            ref={el => observerRefs.current[index] = el}
            className="solution-card opacity-0 transform translate-y-10 transition-all duration-1000 ease-out"
          >
            <div className="bg-white/90 rounded-2xl p-12 shadow-xl backdrop-blur-lg border border-gray-100">
              <div className="max-w-5xl mx-auto">
                <div className="flex items-center gap-6 mb-8">
                  <div className="p-4 bg-gray-50 rounded-xl">
                    <solution.icon size={40} className="text-[#2a2a2a]" />
                  </div>
                  <h3 className="text-3xl font-bold text-[#2a2a2a] tracking-tight">
                    {solution.title}
                  </h3>
                </div>
                <p className="text-xl font-semibold text-[#4a4a4a] mb-6 tracking-tight">
                  {solution.subtitle}
                </p>
                <p className="text-lg leading-relaxed text-[#333333] mb-10">
                  {solution.description}
                </p>
                <div className="bg-gray-50 rounded-xl p-8">
                  <h4 className="font-bold text-xl mb-6 text-[#2a2a2a] tracking-tight">
                    {language === 'en' ? 'Key Benefits:' : 'Avantages clés:'}
                  </h4>
                  <ul className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {solution.benefits.map((benefit, idx) => (
                      <li key={idx} className="flex items-center text-lg text-[#333333]">
                        <span className="w-2.5 h-2.5 bg-[#2a2a2a] rounded-full mr-4"></span>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ContactForm />
      <style>{`
        .solution-card {
          opacity: 0;
          transform: translateY(60px);
        }
        .fade-in-visible {
          opacity: 1;
          transform: translateY(0);
        }
        @media (max-width: 768px) {
          .solution-card ul {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </div>
  );
};

export default SalesDirectionOutsourcingPage;