import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SalesStrategyPage from './pages/SalesStrategyPage';
import SalesDirectionOutsourcingPage from './pages/SalesDirectionOutsourcingPage';
import AISalesOptimizationPage from './pages/AISalesOptimization';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services/sales-strategy" element={<SalesStrategyPage />} />
        <Route path="/services/sales-direction-outsourcing" element={<SalesDirectionOutsourcingPage />} />
        <Route path="/services/ai-sales-optimization" element={<AISalesOptimizationPage />} />
      </Routes>
    </Router>
  );
};

export default App;