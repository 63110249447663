import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, BarChart4, Boxes, Users, BatteryCharging, Presentation, CheckSquare, Target, Webhook, Award } from 'lucide-react';
import ContactForm from '../common/ContactForm';
import LanguageToggleButton from '../common/LanguageToggleButton';
import SEO from '../common/SEO';

const BackButton = () => {
  return (
    <Link to="/" className="flex items-center space-x-2 text-gray-600 hover:text-gray-800">
      <ChevronLeft className="w-5 h-5" />
      <span className="text-sm font-medium">Back</span>
    </Link>
  );
};

const AISalesOptimizationPage: React.FC = () => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'fr' : 'en'));
  };

  const observerRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-visible');
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: '0px'
      }
    );

    observerRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => observer.disconnect();
  }, []);

  const introText = {
    en: (
      <>
        <p>
          Struggling to scale up sales and drive growth? An <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>AI-based approach</span> to your sales strategy can make all the difference.
        </p>
        <p>
          Pain Points: <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>Manual processes</span>, <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>unstructured approaches</span>, <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>lack of automation</span>, and <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>limited data insights</span> can hinder your growth.
        </p>
        <p>
          Our Solution: With AI-driven sales optimization, we provide expert guidance to <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>automate processes</span>, <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>structure your approach</span>, <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>leverage AI methodologies</span>, and <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>gain data insights</span> effectively.
        </p>
        <p>
          Benefits: Achieve <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>scalable growth</span>, manage your sales team efficiently, and drive real business impact with flexible and <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>cost-effective AI solutions</span>.
        </p>
      </>
    ),
    fr: (
      <>
        <p>
          Vous avez du mal à augmenter vos ventes et à stimuler la croissance ? Une <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>approche basée sur l'IA</span> de votre stratégie commerciale peut faire toute la différence.
        </p>
        <p>
          Difficultés : <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>Des processus manuels</span>, <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>des approches non structurées</span>, <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>un manque d'automatisation</span> et <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>des analyses de données limitées</span> peuvent freiner votre croissance.
        </p>
        <p>
          Notre solution : Avec l'optimisation des ventes par l'IA, nous fournissons des conseils d'experts pour <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>automatiser les processus</span>, <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>structurer votre approche</span>, <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>tirer parti des méthodologies IA</span> et <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>obtenir des analyses de données</span> efficacement.
        </p>
        <p>
          Avantages : Réalisez <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>une croissance évolutive</span>, gérez efficacement votre équipe de vente et ayez un réel impact sur votre entreprise avec des solutions flexibles et <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>rentables basées sur l'IA</span>.
        </p>
      </>
    )
  };

  const painPoints = [
    {
      icon: BarChart4,
      title: language === 'en' ? "Manual Processes" : "Processus manuels",
      description: language === 'en' ? "Relying on manual processes leads to inefficiencies and errors." : "S'appuyer sur des processus manuels entraîne des inefficacités et des erreurs."
    },
    {
      icon: Boxes,
      title: language === 'en' ? "Unstructured Approaches" : "Approches non structurées",
      description: language === 'en' ? "Lack of structured approaches results in inconsistent outcomes." : "L'absence d'approches structurées entraîne des résultats incohérents."
    },
    {
      icon: Users,
      title: language === 'en' ? "Lack of Automation" : "Manque d'automatisation",
      description: language === 'en' ? "Not automating tasks wastes time and resources." : "Ne pas automatiser les tâches gaspille du temps et des ressources."
    },
    {
      icon: BatteryCharging,
      title: language === 'en' ? "Limited Data Insights" : "Analyses de données limitées",
      description: language === 'en' ? "Limited data insights hinder informed decision-making." : "Des analyses de données limitées entravent la prise de décision éclairée."
    }
  ];

  const solutions = [
    {
      icon: Presentation,
      title: language === 'en' ? "On-demand Strategic Support" : "Support stratégique à la demande",
      subtitle: language === 'en' ? "Free up time for long-term strategy" : "Libérez du temps pour la stratégie à long terme",
      description: language === 'en' ? "We help you define and implement a clear sales strategy while freeing you from daily constraints to focus on what's essential. Our strategic support adapts to your schedule and needs, providing expert guidance exactly when you need it most." : "Nous vous aidons à définir et à mettre en œuvre une stratégie commerciale claire tout en vous libérant des contraintes quotidiennes pour vous concentrer sur l'essentiel. Notre support stratégique s'adapte à votre emploi du temps et à vos besoins, offrant des conseils d'experts exactement quand vous en avez le plus besoin.",
      benefits: language === 'en' ? [
        "Flexible strategic planning sessions",
        "Regular strategy reviews and adjustments",
        "Priority-based support system",
        "Clear action plans and milestones"
      ] : [
        "Sessions de planification stratégique flexibles",
        "Révisions et ajustements réguliers de la stratégie",
        "Système de support basé sur les priorités",
        "Plans d'action clairs et jalons"
      ]
    },
    {
      icon: CheckSquare,
      title: language === 'en' ? "Sales Process Structuring and Optimization" : "Structuration et optimisation du processus de vente",
      subtitle: language === 'en' ? "Optimize your sales process" : "Optimisez votre processus de vente",
      description: language === 'en' ? "We assist you in implementing efficient sales processes, adapted to your needs and market, to maximize the effectiveness of your sales actions. Our structured approach ensures every step of your sales process is optimized for maximum impact." : "Nous vous aidons à mettre en œuvre des processus de vente efficaces, adaptés à vos besoins et à votre marché, pour maximiser l'efficacité de vos actions commerciales. Notre approche structurée garantit que chaque étape de votre processus de vente est optimisée pour un impact maximal.",
      benefits: language === 'en' ? [
        "Complete sales process audit",
        "Custom optimization strategies",
        "Implementation support",
        "Performance tracking systems"
      ] : [
        "Audit complet du processus de vente",
        "Stratégies d'optimisation personnalisées",
        "Support à la mise en œuvre",
        "Systèmes de suivi des performances"
      ]
    },
    {
      icon: Target,
      title: language === 'en' ? "Part-time Sales Direction" : "Direction commerciale à temps partiel",
      subtitle: language === 'en' ? "Develop a sales strategy with optimized resources" : "Développez une stratégie commerciale avec des ressources optimisées",
      description: language === 'en' ? "We provide the necessary expertise to develop and execute sales action plans while optimizing your internal resources, with flexible management adapted to your means. Get the benefits of experienced sales leadership without the full-time commitment." : "Nous fournissons l'expertise nécessaire pour développer et exécuter des plans d'action commerciaux tout en optimisant vos ressources internes, avec une gestion flexible adaptée à vos moyens. Bénéficiez des avantages d'un leadership commercial expérimenté sans l'engagement à temps plein.",
      benefits: language === 'en' ? [
        "Experienced sales leadership",
        "Resource optimization",
        "Flexible commitment options",
        "Strategic planning and execution"
      ] : [
        "Leadership commercial expérimenté",
        "Optimisation des ressources",
        "Options d'engagement flexibles",
        "Planification et exécution stratégiques"
      ]
    },
    {
      icon: Webhook,
      title: language === 'en' ? "Flexible and Custom Strategic Support" : "Support stratégique flexible et personnalisé",
      subtitle: language === 'en' ? "Free yourself from overwork" : "Libérez-vous de la surcharge de travail",
      description: language === 'en' ? "We adapt to your priorities and help you delegate sales management to lighten your workload, allowing you to focus on overall business management. Our flexible support system ensures you get the help you need, when you need it." : "Nous nous adaptons à vos priorités et vous aidons à déléguer la gestion des ventes pour alléger votre charge de travail, vous permettant de vous concentrer sur la gestion globale de l'entreprise. Notre système de support flexible garantit que vous obtenez l'aide dont vous avez besoin, quand vous en avez besoin.",
      benefits: language === 'en' ? [
        "Customized support plans",
        "Workload optimization",
        "Priority management",
        "Delegation strategies"
      ] : [
        "Plans de support personnalisés",
        "Optimisation de la charge de travail",
        "Gestion des priorités",
        "Stratégies de délégation"
      ]
    },
    {
      icon: Award,
      title: language === 'en' ? "KPI Implementation and Performance Monitoring" : "Mise en œuvre des KPI et suivi des performances",
      subtitle: language === 'en' ? "Track and adjust your sales results" : "Suivez et ajustez vos résultats de vente",
      description: language === 'en' ? "We help you define clear key performance indicators (KPIs), track your sales results, and quickly adjust your actions to improve performance. Our data-driven approach ensures your sales strategy is always aligned with your business goals." : "Nous vous aidons à définir des indicateurs clés de performance (KPI) clairs, à suivre vos résultats de vente et à ajuster rapidement vos actions pour améliorer les performances. Notre approche basée sur les données garantit que votre stratégie commerciale est toujours alignée sur vos objectifs commerciaux.",
      benefits: language === 'en' ? [
        "Custom KPI development",
        "Real-time performance tracking",
        "Regular performance reviews",
        "Data-driven strategy adjustments"
      ] : [
        "Développement de KPI personnalisés",
        "Suivi des performances en temps réel",
        "Révisions régulières des performances",
        "Ajustements stratégiques basés sur les données"
      ]
    }
  ];

  return (
    <div className="relative z-10 max-w-7xl mx-auto font-sans p-6">
      <SEO pageTitle="Besales - AI-Powered Sales Optimization" pageDescription="Besales by Sophie Monteragioni helps you integrate AI technologies to streamline and enhance your sales operations." />
      <div className="flex justify-between items-center mb-8">
        <BackButton />
        <LanguageToggleButton language={language} toggleLanguage={toggleLanguage} />
      </div>

      <h1 className="text-4xl font-extrabold mb-12 text-gray-800 tracking-tight leading-tight">
        {language === 'en' ? 'AI-Powered Sales Optimization' : 'Optimisation des ventes par IA'}
      </h1>

      <div className="space-y-6 text-base text-gray-700">
        {language === 'en' ? introText.en : introText.fr}
      </div>

      <h2 className="text-3xl font-extrabold mt-12 mb-6 text-gray-800 tracking-tight leading-tight">
        {language === 'en' ? 'Common Challenges' : 'Défis Communs'}
      </h2>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {painPoints.map((point, index) => (
          <div key={index} className="bg-white rounded-lg p-6 shadow-md border border-gray-200">
            <point.icon size={32} className="mb-3 text-[#4a4a4a]" />
            <h3 className="text-xl font-semibold mb-2">{point.title}</h3>
            <p className="text-gray-700">{point.description}</p>
          </div>
        ))}
      </div>

      <h2 className="text-3xl font-extrabold mt-12 mb-6 text-gray-800 tracking-tight leading-tight">
        {language === 'en' ? 'Our Solutions' : 'Nos Solutions'}
      </h2>
      <div className="space-y-8">
        {solutions.map((solution, index) => (
          <div
            key={index}
            ref={el => observerRefs.current[index] = el}
            className="solution-card opacity-0 transform translate-y-10 transition-all duration-1000 ease-out"
          >
            <div className="bg-white/90 rounded-2xl p-12 shadow-xl backdrop-blur-lg border border-gray-100">
              <div className="max-w-5xl mx-auto">
                <div className="flex items-center gap-6 mb-8">
                  <div className="p-4 bg-gray-50 rounded-xl">
                    <solution.icon size={40} className="text-[#2a2a2a]" />
                  </div>
                  <h3 className="text-3xl font-bold text-[#2a2a2a] tracking-tight">
                    {solution.title}
                  </h3>
                </div>
                <p className="text-xl font-semibold text-[#4a4a4a] mb-6 tracking-tight">
                  {solution.subtitle}
                </p>
                <p className="text-lg leading-relaxed text-[#333333] mb-10">
                  {solution.description}
                </p>
                <div className="bg-gray-50 rounded-xl p-8">
                  <h4 className="font-bold text-xl mb-6 text-[#2a2a2a] tracking-tight">
                    {language === 'en' ? 'Key Benefits:' : 'Avantages clés:'}
                  </h4>
                  <ul className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {solution.benefits.map((benefit, idx) => (
                      <li key={idx} className="flex items-center text-lg text-[#333333]">
                        <span className="w-2.5 h-2.5 bg-[#2a2a2a] rounded-full mr-4"></span>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-12">
        <ContactForm />
      </div>

      <style>{`
        .solution-card {
          opacity: 0;
          transform: translateY(60px);
        }
        .fade-in-visible {
          opacity: 1;
          transform: translateY(0);
        }
        @media (max-width: 768px) {
          .solution-card ul {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </div>
  );
};

export default AISalesOptimizationPage;