import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, TrendingUp, BarChart4, Users, BatteryCharging, LineChart, Presentation, CheckSquare, Target, Webhook, Award } from 'lucide-react';
import ContactForm from '../common/ContactForm';
import LanguageToggleButton from '../common/LanguageToggleButton';
import SEO from '../common/SEO';

const BackButton = () => {
  return (
    <Link to="/" className="flex items-center space-x-2 text-gray-600 hover:text-gray-800">
      <ChevronLeft className="w-5 h-5" />
      <span className="text-sm font-medium">Back</span>
    </Link>
  );
};

const SalesStrategyPage: React.FC = () => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'fr' : 'en'));
  };

  const observerRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-visible');
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: '0px'
      }
    );

    observerRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => observer.disconnect();
  }, []);

  const introText = {
    en: (
      <>
        <p>
          Developing a comprehensive sales strategy is crucial for achieving your business goals. A well-defined strategy helps you align your sales efforts with your target market and business objectives.
        </p>
        <p>
          Our Solution: <span className="text-2xl font-bold">Comprehensive Sales Strategy</span>: We help you create a tailored sales strategy that drives growth and maximizes your sales potential.
        </p>
        <p>
          Your Benefits: Focus on <span className="text-2xl font-bold">scaling and optimizing</span> your sales processes to achieve sustainable growth and success.
        </p>
      </>
    ),
    fr: (
      <>
        <p>
          Développer une stratégie de vente complète est crucial pour atteindre vos objectifs commerciaux. Une stratégie bien définie vous aide à aligner vos efforts de vente avec votre marché cible et vos objectifs commerciaux.
        </p>
        <p>
          Notre solution : <span className="text-2xl font-bold">Stratégie de vente complète</span> : Nous vous aidons à créer une stratégie de vente sur mesure qui favorise la croissance et maximise votre potentiel de vente.
        </p>
        <p>
          Vos avantages : Concentrez-vous sur <span className="text-2xl font-bold">l'échelle et l'optimisation</span> de vos processus de vente pour atteindre une croissance et un succès durables.
        </p>
      </>
    )
  };

  const painPoints = [
    {
      icon: BarChart4,
      title: language === 'en' ? "Unclear Sales Strategy" : "Stratégie de vente peu claire",
      description: language === 'en' ? "Lack of a clear sales strategy can lead to inefficiencies and missed opportunities." : "L'absence d'une stratégie de vente claire peut entraîner des inefficacités et des opportunités manquées."
    },
    {
      icon: Users,
      title: language === 'en' ? "Misaligned Sales Efforts" : "Efforts de vente mal alignés",
      description: language === 'en' ? "Sales efforts that are not aligned with business objectives can hinder growth." : "Les efforts de vente qui ne sont pas alignés avec les objectifs commerciaux peuvent freiner la croissance."
    },
    {
      icon: BatteryCharging,
      title: language === 'en' ? "Inefficient Sales Processes" : "Processus de vente inefficaces",
      description: language === 'en' ? "Inefficient sales processes can lead to wasted resources and lower performance." : "Des processus de vente inefficaces peuvent entraîner un gaspillage de ressources et une performance inférieure."
    },
    {
      icon: LineChart,
      title: language === 'en' ? "Lack of Performance Tracking" : "Absence de suivi des performances",
      description: language === 'en' ? "Without performance tracking, it's difficult to measure success and make improvements." : "Sans suivi des performances, il est difficile de mesurer le succès et d'apporter des améliorations."
    },
    {
      icon: LineChart,
      title: language === 'en' ? "Technology Integration" : "Intégration technologique",
      description: language === 'en' ? "Integrating and utilizing sales technology effectively can be a significant hurdle." : "L'intégration et l'utilisation efficace de la technologie de vente peuvent être un obstacle important."
    }
  ];

  const solutions = [
    {
      icon: Presentation,
      title: language === 'en' ? "Tailored Sales Strategy" : "Stratégie de vente sur mesure",
      subtitle: language === 'en' ? "Align your sales efforts with your business goals" : "Alignez vos efforts de vente avec vos objectifs commerciaux",
      description: language === 'en' ? "We help you create a tailored sales strategy that aligns with your business goals and target market." : "Nous vous aidons à créer une stratégie de vente sur mesure qui s'aligne avec vos objectifs commerciaux et votre marché cible.",
      benefits: language === 'en' ? [
        "Customized sales strategy",
        "Alignment with business goals",
        "Target market focus",
        "Clear action plans"
      ] : [
        "Stratégie de vente personnalisée",
        "Alignement avec les objectifs commerciaux",
        "Ciblage du marché cible",
        "Plans d'action clairs"
      ]
    },
    {
      icon: CheckSquare,
      title: language === 'en' ? "Sales Process Optimization" : "Optimisation des processus de vente",
      subtitle: language === 'en' ? "Improve the efficiency of your sales processes" : "Améliorez l'efficacité de vos processus de vente",
      description: language === 'en' ? "We assist you in optimizing your sales processes to improve efficiency and performance." : "Nous vous aidons à optimiser vos processus de vente pour améliorer l'efficacité et les performances.",
      benefits: language === 'en' ? [
        "Process audit",
        "Optimization strategies",
        "Implementation support",
        "Performance tracking"
      ] : [
        "Audit des processus",
        "Stratégies d'optimisation",
        "Support à la mise en œuvre",
        "Suivi des performances"
      ]
    },
    {
      icon: Target,
      title: language === 'en' ? "Performance Tracking and Analysis" : "Suivi et analyse des performances",
      subtitle: language === 'en' ? "Measure and improve your sales performance" : "Mesurez et améliorez vos performances de vente",
      description: language === 'en' ? "We help you implement performance tracking systems to measure and improve your sales performance." : "Nous vous aidons à mettre en place des systèmes de suivi des performances pour mesurer et améliorer vos performances de vente.",
      benefits: language === 'en' ? [
        "Performance tracking systems",
        "Regular performance reviews",
        "Data-driven improvements",
        "Strategic adjustments"
      ] : [
        "Systèmes de suivi des performances",
        "Révisions régulières des performances",
        "Améliorations basées sur les données",
        "Ajustements stratégiques"
      ]
    },
    {
      icon: Webhook,
      title: language === 'en' ? "Flexible and Custom Strategic Support" : "Support stratégique flexible et personnalisé",
      subtitle: language === 'en' ? "Free yourself from overwork" : "Libérez-vous de la surcharge de travail",
      description: language === 'en' ? "We adapt to your priorities and help you delegate sales management to lighten your workload, allowing you to focus on overall business management. Our flexible support system ensures you get the help you need, when you need it." : "Nous nous adaptons à vos priorités et vous aidons à déléguer la gestion des ventes pour alléger votre charge de travail, vous permettant de vous concentrer sur la gestion globale de l'entreprise. Notre système de support flexible garantit que vous obtenez l'aide dont vous avez besoin, quand vous en avez besoin.",
      benefits: language === 'en' ? [
        "Customized support plans",
        "Workload optimization",
        "Priority management",
        "Delegation strategies"
      ] : [
        "Plans de support personnalisés",
        "Optimisation de la charge de travail",
        "Gestion des priorités",
        "Stratégies de délégation"
      ]
    },
    {
      icon: Award,
      title: language === 'en' ? "KPI Implementation and Performance Monitoring" : "Mise en œuvre des KPI et suivi des performances",
      subtitle: language === 'en' ? "Track and adjust your sales results" : "Suivez et ajustez vos résultats de vente",
      description: language === 'en' ? "We help you define clear key performance indicators (KPIs), track your sales results, and quickly adjust your actions to improve performance. Our data-driven approach ensures your sales strategy is always aligned with your business goals." : "Nous vous aidons à définir des indicateurs clés de performance (KPI) clairs, à suivre vos résultats de vente et à ajuster rapidement vos actions pour améliorer les performances. Notre approche basée sur les données garantit que votre stratégie commerciale est toujours alignée sur vos objectifs commerciaux.",
      benefits: language === 'en' ? [
        "Custom KPI development",
        "Real-time performance tracking",
        "Regular performance reviews",
        "Data-driven strategy adjustments"
      ] : [
        "Développement de KPI personnalisés",
        "Suivi des performances en temps réel",
        "Révisions régulières des performances",
        "Ajustements stratégiques basés sur les données"
      ]
    }
  ];

  return (
    <div className="relative z-10 max-w-7xl mx-auto font-sans p-6">
      <SEO pageTitle="Besales - Comprehensive Sales Strategy" pageDescription="Besales by Sophie Monteragioni helps you create a tailored sales strategy that drives growth and maximizes your sales potential." />
      <div className="flex justify-between items-center mb-8">
        <BackButton />
        <LanguageToggleButton language={language} toggleLanguage={toggleLanguage} />
      </div>

      <h1 className="text-4xl font-extrabold mb-12 text-gray-800 tracking-tight leading-tight">
        {language === 'en' ? 'Comprehensive Sales Strategy' : 'Stratégie de vente complète'}
      </h1>

      <div className="space-y-6 text-base text-gray-700">
        {language === 'en' ? introText.en : introText.fr}
      </div>

      <h2 className="text-3xl font-extrabold mt-12 mb-6 text-gray-800 tracking-tight leading-tight">
        {language === 'en' ? 'Common Challenges' : 'Défis Communs'}
      </h2>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {painPoints.map((point, index) => (
          <div key={index} className="bg-white rounded-lg p-6 shadow-md border border-gray-200">
            <point.icon size={32} className="mb-3 text-[#4a4a4a]" />
            <h3 className="text-xl font-semibold mb-2">{point.title}</h3>
            <p className="text-gray-700">{point.description}</p>
          </div>
        ))}
      </div>

      <h2 className="text-3xl font-extrabold mt-12 mb-6 text-gray-800 tracking-tight leading-tight">
        {language === 'en' ? 'Our Solutions' : 'Nos Solutions'}
      </h2>
      <div className="space-y-8">
        {solutions.map((solution, index) => (
          <div
            key={index}
            ref={el => observerRefs.current[index] = el}
            className="solution-card opacity-0 transform translate-y-10 transition-all duration-1000 ease-out"
          >
            <div className="bg-white/90 rounded-2xl p-12 shadow-xl backdrop-blur-lg border border-gray-100">
              <div className="max-w-5xl mx-auto">
                <div className="flex items-center gap-6 mb-8">
                  <div className="p-4 bg-gray-50 rounded-xl">
                    <solution.icon size={40} className="text-[#2a2a2a]" />
                  </div>
                  <h3 className="text-3xl font-bold text-[#2a2a2a] tracking-tight">
                    {solution.title}
                  </h3>
                </div>
                <p className="text-xl font-semibold text-[#4a4a4a] mb-6 tracking-tight">
                  {solution.subtitle}
                </p>
                <p className="text-lg leading-relaxed text-[#333333] mb-10">
                  {solution.description}
                </p>
                <div className="bg-gray-50 rounded-xl p-8">
                  <h4 className="font-bold text-xl mb-6 text-[#2a2a2a] tracking-tight">
                    {language === 'en' ? 'Key Benefits:' : 'Avantages clés:'}
                  </h4>
                  <ul className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {solution.benefits.map((benefit, idx) => (
                      <li key={idx} className="flex items-center text-lg text-[#333333]">
                        <span className="w-2.5 h-2.5 bg-[#2a2a2a] rounded-full mr-4"></span>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-12">
        <ContactForm />
      </div>
      
      <style>{`
        .solution-card {
          opacity: 0;
          transform: translateY(60px);
        }
        .fade-in-visible {
          opacity: 1;
          transform: translateY(0);
        }
        @media (max-width: 768px) {
          .solution-card ul {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </div>
  );
};

export default SalesStrategyPage;