import { WithContext, Service } from "schema-dts";
import { Helmet } from "react-helmet";


// Function to generate the structured data
const getStructuredData = (): WithContext<Service> => ({
    '@context': 'https://schema.org',
    '@type': 'Service',
    name: 'Besales',
    description: 'Scale and optimize your sales by outsourcing Sales Direction',
    provider: {
      '@type': 'Person',
      name: 'Sophie Monteragioni'
    }
  });

interface SEOProps {
  pageTitle: string;
  pageDescription: string;
}

const SEO = ({ pageTitle, pageDescription }: SEOProps) => (
    <Helmet>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(getStructuredData()) }}
        />
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
    </Helmet>
);

export default SEO;